/* eslint-env serviceworker */
/* globals ZencastrAudioProcessor */

var self = this

/**
 * We will save the stats that come out of the mic processor in this object
 * On 'reinit' we'll need to restart the processor.
 * When the second processor stops, we'll compile those stats with these ones and fire the final event
 * @type {Object}
 */
var _statsCache = null

class GenericWasmProcessor {
  constructor (processorOptions) {
    this.aborted = false
    this.isLastReceived = false
    this.processingLast = false
    this.videoRecording = false
    this.format = 'mp3'
    this.processorOptions = { ...processorOptions }
  }

  lastReceived () {
    this.isLastReceived = true
  }

  signalMainThread (format = 'mp3', type = 'microphone') {
    self.postMessage({
      command: 'lastChunkProcessed',
      format: format,
      type: type
    })
  }

  signalTrackFinalization () {
    if (this.name === 'microphone') {
      this.signalMainThread()

      // in case the user also had a wav pipeline
      if (self.pipeline['wavmicrophone']) {
        this.signalMainThread('wav')
      }

      // if we have a video recording
      if (this.videoRecording) {
        // only mov files go through this worker so we can hard code mov here
        this.signalMainThread('mov', 'video')
      }
    } else if (this.name === 'soundboard') {
      this.signalMainThread('mp3', 'soundboard')
    }
  }
}

class WebmWasmProcessor extends GenericWasmProcessor {
  constructor (name, processorOptions) {
    super(processorOptions)
    this.name = name
    this.videoRecording = !!processorOptions.onMov
    this.blobsToProcess = [] // Fifo for async Blobs processing

    // blob used to buffer last blobs that exit the compressor
    this._mp3Tail = new Blob()
    this._wavTail = new Blob()
  }

  processChunk (chunk) {
    if (this.isLastReceived) {
      console.error('Getting additional chunks after last')

      // do not abort multiple times
      if (this.aborted) return

      // if this happens we should abort the recording
      this.aborted = true
      self.postMessage({
        command: 'abort',
        error: 'Aborting recording that was in unrecoverable state.'
      })
      return
    }

    if (chunk instanceof Blob) {
      // If chunk is small, cache it for the next time
      // 3Mb for the first video chunk, and 1Kb for audio
      const requiredChunkSize = this.videoRecording ? (this.processor ? 128 : 3072) * 1024 : 1024
      if (this.cachedBlob) {
        chunk = new Blob([this.cachedBlob, chunk])
        this.cachedBlob = null
      }
      if (chunk.size < requiredChunkSize) {
        this.cachedBlob = chunk
        return
      }
    }

    this.blobsToProcess.push(chunk)
    // if it is the only blob in queue process it
    if (this.blobsToProcess.length === 1) {
      this._processNextBlob()
    }
  }

  async _processNextBlob () {
    console.assert(this.blobsToProcess.length !== 0, 'Trying to process empty blobs queue')

    // next thing in queue
    var blob = this.blobsToProcess[0]

    // if we have a command
    while (typeof blob === 'string') {
      // reinit the processor. used when the input stream changed
      if (blob === 'reinit') {
        if (this.processor) {
          // finalise and destroy the previous
          this.processor.setNoTrailer()
          this.processor.process()
          this.processor.deinit()
          this.processor = null
        }
      }
      this.blobsToProcess.shift()
      // if the queue is empty, return
      if (this.blobsToProcess.length === 0) {
        return
      } else {
        // if we have new blobs in queue, process them next
        blob = this.blobsToProcess[0]
      }
    }

    let data = blob
    if (blob instanceof Blob) {
      let buffer = await blob.arrayBuffer()
      data = new Uint8Array(buffer)
    }

    if (this.processor) {
      try {
        this.processor.process(data)
      } catch (e) {
        // if .process() throws then we need to abort the recording
        self.postMessage({
          command: 'abort',
          error: e.message
        })
      }
    } else {
      this.processor = new ZencastrAudioProcessor(data, this.processorOptions)
    }

    // remove it from queue
    this.blobsToProcess.shift()

    // if we still have data to process, go again
    if (this.blobsToProcess.length) {
      this._processNextBlob()
    } else if (this.isLastReceived) { // queue is empty, and no more blobs are expected
      this.processLast()
    }
  }

  /**
   * Called when we need to reinit the wasm audio pipeline
   * It actually stops the old processor. We will start a new one after which will not add footers and headers to mp3
   * @param  {Number} delay How many miliseconds we need to add
   *                        This represents the time between when we stopped the old media recorder
   *                        and the first chunk from the new one
   */
  processReinit (delay) {
    // change type of the next created processor
    this.processorOptions.startDelayMs = delay
    this.processorOptions.audioType = ZencastrAudioProcessor.prototype.AudioType.MicrophoneNoHeader
    this.processChunk('reinit')
  }

  /**
   * Called when we've received the isLast flag from the main thread
   */
  lastReceived () {
    super.lastReceived()
    if (this.cachedBlob) {
      this.blobsToProcess.push(this.cachedBlob)
      this.cachedBlob = null
      // if it is the only blob in queue process it
      if (this.blobsToProcess.length === 1) {
        this._processNextBlob()
      }
    } else if (this.blobsToProcess.length === 0) {
      this.processLast()
    }
  }

  processLast () {
    this.processingLast = true
    this.processor.process()
    this.processingLast = false

    // manually call the callback to send that last blob
    this.processorOptions.onMp3(this._mp3Tail, true)

    if (this.processorOptions.onRaw) {
      this.processorOptions.onRaw(this._wavTail, true)
      this._wavTail = null
    }

    this._mp3Tail = null

    this.signalTrackFinalization()
  }
}

this.onmessage = function (e) {
  switch (e.data.command) {
    case 'init':
      this.init(e.data.config)
      break
    case 'addPipeline':
      this.addPipeline(e.data.config)
      break
    case 'getWebmInfo':
      this.getWebmInfo(e.data.data)
      break
    case 'flushLogsOnClose':
      self.Logger.flushLogsOnClose()
      break
  }
}

this.init = function (config = {nodes: []}) {
  if (config.wasmLocation) {
    self.wasmLocation = config.wasmLocation
  }

  // import any scripts used by multiple nodes so we don't have to load them for each node
  importScripts.apply(self, config.commonScripts)

  // if we should also output pcm
  this.rawOutput = config.rawOutput
  // if we should also record video
  this.videoRecording = config.videoRecording
  // if we shoudl record soundboard
  this.recordSoundboard = config.recordSoundboard

  this.cameraOn = config.cameraOn

  this.dataPort = config.dataPort

  // we use a special port to only receive data
  if (this.dataPort) {
    this.dataPort.onmessage = this.handleDataMessage.bind(this)
  }

  this.pipeline = {}

  // wait for the wasm module to be instantiated
  // and signal to the main thread
  ZencastrAudioProcessor.ready.then(() => {
    this.postMessage({command: 'ready'})
    ZencastrAudioProcessor.setLogVerbosity(0)
  })

  this.processors = {}

  this.processorOptions = {
    zenIstrFmt: config.useInsertableStreams ? 1 : 0,
    mp3ChunkSize: config.mp3ChunkSize || 65536,
    onMp3: this.mp3DataAvailable.bind(this),
    onStats: (data) => {
      this.parseStats(data, 'microphone')
    },
    audioType: ZencastrAudioProcessor.prototype.AudioType.Microphone
  }

  if (this.videoRecording) {
    this.processorOptions.onMov = (data, isLast) => {
      this.videoDataAvailable(data, isLast)
    }
  }

  if (this.rawOutput) {
    this.processorOptions.rawChunkSize = config.rawChunkSize || 88200
    this.processorOptions.onRaw = this.rawDataAvailable.bind(this)
  }

  this.processors['microphone'] = new WebmWasmProcessor('microphone', this.processorOptions)

  if (this.recordSoundboard) {
    this.soundboardProcessorOptions = {
      onMp3: this.soundboardDataAvailable.bind(this),
      mp3ChunkSize: config.mp3ChunkSize || 65536,
      onStats: (data) => {
        this.parseStats(data, 'soundboard')
      },
      audioType: ZencastrAudioProcessor.prototype.AudioType.Soundboard
    }

    this.processors['soundboard'] = new WebmWasmProcessor('soundboard', this.soundboardProcessorOptions)
  }

  if (self.Logger && config.logging) {
    self.Logger.initialize(config.logging)
    self.Logger.enableDebugModule()
  }
}

this.handleDataMessage = function (e) {
  var tag = e.data.tag
  var chunk = e.data.chunk

  // we are recording using the MediaRecorder
  var processor = this.processors[tag]

  if (!processor) {
    self.postMessage({
      command: 'abort',
      error: 'Could not find processor for tag: ' + tag
    })
    return
  }

  // we are getting some chunks after the processor is aborted. ignore them because they might crash the tab
  if (processor.aborted) {
    return
  }

  if (chunk) {
    // chunks should be blobs or arraybuffer
    if (!(chunk instanceof Blob || chunk instanceof Uint8Array)) {
      self.postMessage({
        command: 'abort',
        error: 'Worker only accepts Blob and Uint8Array'
      })
      return
    }

    if (chunk.size === 0) {
      self.postMessage({
        command: 'abort',
        error: 'Received a 0 sized blob'
      })
      return
    }

    processor.processChunk(chunk)
  }

  if (e.data.reinit) {
    processor.processReinit(e.data.delay)
  }
  if (e.data.isLast) {
    processor.lastReceived()
  }
}

this.addPipeline = function (config) {
  var name = config.name
  var nodes = config.nodes

  if (!this.pipeline[name]) {
    this.pipeline[name] = []
  }

  nodes.forEach(function (nodeConf) {
    var node = this.createPipelineNode(nodeConf, this.pipeline[name])
    this.pushPipelineNode(node, this.pipeline[name])
  })
}

this.createPipelineNode = function (nodeConf, pipeline = this.pipeline) {
  // load scripts / dependencies for the processor
  nodeConf.scripts && importScripts.apply(self, nodeConf.scripts)

  // a bit hacky but some nodes need to reference others (CloudStore needs MemoryStore)
  nodeConf.constructorConf.pipeline = pipeline

  // construct node
  var nodeInstance = new this[nodeConf.constructorName](nodeConf.constructorConf)

  return nodeInstance
}

this.pushPipelineNode = function (node, pipeline) {
  if (pipeline.length) {
    // stop listening to the previous end node for output chunks
    var endNode = pipeline[pipeline.length - 1]

    // pipe last end node into new end node
    endNode.pipe(node)
  }

  pipeline.push(node)
}

/**
 * Used to get webm stats for an audio blob
 * Useful when trying to find the true sample rate from an input device
 * @param  {Blob} blob Audio blob
 */
this.getWebmInfo = function (blob) {
  if (!(blob instanceof Blob)) return

  if (blob.size === 0) {
    console.error('Received 0 size blob in getWebmInfo.')
    this.postMessage({
      command: 'webmInfo',
      data: {
        sample_rate: 0
      }
    })
    return
  }

  blob.arrayBuffer().then((arrayBuffer) => {
    var data = new Uint8Array(arrayBuffer)

    try {
      var processor = new ZencastrAudioProcessor(data, {
        onStats: (stats) => {
          this.postMessage({
            command: 'webmInfo',
            data: stats
          })
        }
      })

      processor.deinit()
      // ignore if this fails
    } catch (e) {}
  })
}

 /**
  * Callbacks for data coming out of wasm
  */

this.rawDataAvailable = function (data, isLast = false) {
  try {
    const processor = this.processors['microphone']
    // if we are processing last chunk
    if (processor.processingLast) {
      processor._wavTail = new Blob([processor._wavTail, data])
      return
    }

    this.pipeline['wavmicrophone'][0].trigger('incomingChunk', new Blob([data]), isLast)
  } catch (e) {
    console.error(e)
  }
}

this.mp3DataAvailable = function (data, isLast = false) {
  try {
    const processor = this.processors['microphone']
    // if we are processing last chunk
    if (processor.processingLast) {
      processor._mp3Tail = new Blob([processor._mp3Tail, data])
      return
    }

    this.pipeline['mp3microphone'][0].trigger('incomingChunk', new Blob([data]), isLast)
  } catch (e) {
    console.error(e)
  }
}

this.soundboardDataAvailable = function (data, isLast = false) {
  try {
    const processor = this.processors['soundboard']
    // if we are processing last chunk
    if (processor.processingLast) {
      processor._mp3Tail = new Blob([processor._mp3Tail, data])
      return
    }

    this.pipeline['mp3soundboard'][0].trigger('incomingChunk', new Blob([data]), isLast)
  } catch (e) {
    console.error(e)
  }
}

this.videoDataAvailable = function (data, isLast) {
  try {
    this.pipeline['movvideo'][0].trigger('incomingChunk', new Blob([data]), isLast)
  } catch (e) {
    console.error(e)
  }
}

/**
 * Other methods
 */

this.parseStats = function (data, tag) {
  if (data.sample_rate) {
    if (self.processors[tag]) {
      self.processors[tag].sampleRate = data.sample_rate
      self.processors[tag].channels = data.channels
    }
  } else if (data.samples_decoded) {
    var toSend = {...data}

    if (tag === 'microphone') {
      // if we have stats cached
      if (_statsCache) {
        for (var key in toSend) {
          // just sum up the values
          if (key !== 'video')
            toSend[key] += _statsCache[key]
        }
      }

      // make a copy of the stats object in case we need it
      _statsCache = {...data}
    }

    self.postMessage({
      command: 'stats',
      tag: tag,
      message: {
        // data contains:
        // samples_decoded
        // samples_encoded
        // frames_decoded
        // frames_encoded
        ...toSend,
        sampleRate: self.processors[tag].sampleRate,
        channels: self.processors[tag].channels
      }
    })
  } else if ('isFrameBlack' in data) {
    // if the camera was on when the recording started but we detected black frames
    if (this.cameraOn && data['isFrameBlack']) {
      console.error('Possible black video recording', data)
    }
  } else {
    console.log('Received strange stats message', tag, data)
  }
}
